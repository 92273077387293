import { useState, useEffect, useCallback, useMemo } from "react";
import { useOutletContext } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import NewManagerModal from "./NewManagerModal.jsx";
import NewEmployeeModal from "./NewEmployeeModal.jsx";
import LinkEmployeeModal from "./LinkEmployeeModal.js";
import SkeletonCreateSurvey from "../../Manage/skeletons/SkeletonCreateSurvey.js";

const CreateSurvey = ({
  employees,
  managers,
  companyTemplates,
  createSurveyHandler,
  setCreateNewSurvey,
  uploadedUsers,
  list,
  newsurvey,
  invite_handler,
  getSurveyOverviewResults,
  draftSurveyDetails,
  setShowFirstPage,
}) => {
  const {
    uploadUsersHandler,
    userDetails,
    notify,
    getTags,
    tags,
    getUsers,
    editUsers,
    updateSurveyHandler,
  } = useOutletContext();

  const [selectedManagers, setSelectedManagers] = useState([]);
  const [currentManager, setCurrentManager] = useState(null);
  const [selectedEmployees, setSelectedEmployees] = useState({});
  const [isLaunchDisabled, setIsLaunchDisabled] = useState(true);
  const [managerInvitesEmployee, setManagerInvitesEmployee] = useState(false);
  const [selfSurveyRequested, setSelfSurveyRequested] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isManagerModalOpen, setIsManagerModalOpen] = useState(false);
  const [isEmployeeModalOpen, setIsEmployeeModalOpen] = useState(false);

  const [selectedCompanyTemplate, setSelectedCompanyTemplate] = useState("");

  const [startDate, setStartDate] = useState("");
  const [surveyDuration, setSurveyDuration] = useState(14);
  const [surveyTitle, setSurveyTitle] = useState("");

  const [inviteBasedSurvey, setInviteBasedSurvey] = useState(true);
  const [linkEmployeeModalOpen, setLinkEmployeeModalOpen] = useState(false);

  const { t } = useTranslation();

  // get search paarams from props

  // if uploadedUsers is not empty, add them to the employees list
  useEffect(() => {
    if (uploadedUsers && newsurvey === "true") {
      let managers = [];
      let employees = {};

      if (invite_handler === "admin") {
        if (
          list === "full" &&
          uploadedUsers.employee &&
          Object.keys(uploadedUsers.employee).length > 0
        ) {
          employees = uploadedUsers.employee;
          setSelectedEmployees(employees);
        }
        const extractmanagerarray = Object.keys(employees || {});

        // ensure all elements in array are integers (for mapping to work correctly)
        managers = extractmanagerarray.map((element) => parseInt(element));
      } else if (invite_handler === "manager") {
        setManagerInvitesEmployee(true);

        if (uploadedUsers?.manager?.length > 0) {
          // extract ids from managers
          managers = uploadedUsers.manager.map((element) => element.id);
        }
      }

      setSelectedManagers(managers);
    }
  }, [uploadedUsers, newsurvey, list, invite_handler]);

  useEffect(() => {
    if (draftSurveyDetails) {
      // calculate duration of survey
      const start_date = new Date(draftSurveyDetails.survey_start_date);
      const end_date = new Date(draftSurveyDetails.survey_closing_date);
      const duration = Math.floor(
        (end_date - start_date) / (1000 * 60 * 60 * 24)
      );

      const pairing_details = draftSurveyDetails.manager_employee_pairing_data;

      setSelectedCompanyTemplate(draftSurveyDetails.company_template.id);
      setStartDate(draftSurveyDetails.survey_start_date);
      setSurveyDuration(duration);
      setSurveyTitle(draftSurveyDetails.block_title);
      setInviteBasedSurvey(draftSurveyDetails.invite_based);
      setSelfSurveyRequested(draftSurveyDetails.self_survey_requested);

      // pair managers with employees

      let managers = [];

      if (draftSurveyDetails.invite_based) {
        setSelectedEmployees(pairing_details);
        const extractmanagerarray = Object.keys(pairing_details || {});

        // ensure all elements in array are integers (for mapping to work correctly)
        managers = extractmanagerarray.map((element) => parseInt(element));
      } else if (draftSurveyDetails.invite_based === false) {
        setManagerInvitesEmployee(true);

        managers = pairing_details.map((element) => element.id);
      }
      setSelectedManagers(managers);
    }
  }, [draftSurveyDetails]);

  useEffect(() => {
    const baserequirements =
      selectedCompanyTemplate && surveyDuration && startDate && surveyTitle;

    const isLaunchEnabled =
      baserequirements &&
      ((managerInvitesEmployee &&
        selectedManagers.length > 0 &&
        Object.keys(selectedEmployees).length === 0) ||
        (!managerInvitesEmployee &&
          inviteBasedSurvey === true &&
          Object.keys(selectedEmployees).length > 0) ||
        (inviteBasedSurvey === false && selectedManagers.length > 0));
    setIsLaunchDisabled(!isLaunchEnabled);
  }, [
    selectedManagers,
    selectedEmployees,
    selectedCompanyTemplate,
    managerInvitesEmployee,
    startDate,
    surveyDuration,
    isLaunchDisabled,
    inviteBasedSurvey,
    surveyTitle,
  ]);

  useEffect(() => {
    if (userDetails.company_id.survey_invite_handler === "manager") {
      setManagerInvitesEmployee(true);
    }
  }, [userDetails?.company_id?.survey_invite_handler]);

  const handleManagerChange = (event) => {
    const managerId = parseInt(event.target.value);
    const isChecked = event.target.checked;

    if (!managerInvitesEmployee && inviteBasedSurvey === true) {
      // Show employees when managerInvitesEmployee is false
      setCurrentManager(managerId);

      // // Automatically pre-select all employees for the selected manager
      // setSelectedEmployees((prevSelectedEmployees) => ({
      //   ...prevSelectedEmployees,
      //   [managerId]: employees
      //     .filter((employee) => employee.manager_user_ids.includes(managerId))
      //     .map((employee) => employee.id),
      // }));

      // Add the current manager to the selected managers
      setSelectedManagers((prevSelectedManagers) => [
        ...prevSelectedManagers,
        managerId,
      ]);
    } else {
      // Toggle the selection of managers when managerInvitesEmployee is true
      setSelectedManagers((prevSelectedManagers) => {
        if (isChecked) {
          return [...prevSelectedManagers, managerId];
        } else {
          return prevSelectedManagers.filter((id) => id !== managerId);
        }
      });
    }
  };

  const handleEmployeeChange = (event) => {
    const employeeId = parseInt(event.target.value);
    const isChecked = event.target.checked;

    setSelectedEmployees((prevSelectedEmployees) => {
      const managerId = currentManager;
      const updatedEmployees = { ...prevSelectedEmployees };

      if (isChecked) {
        // Add employee to the selectedEmployees mapping for the manager
        if (updatedEmployees[managerId]) {
          updatedEmployees[managerId].push(employeeId);
        } else {
          updatedEmployees[managerId] = [employeeId];
        }

        // Check the manager when an employee is selected
        setSelectedManagers((prior) => {
          if (!prior.includes(managerId)) {
            return [...prior, managerId];
          }
          return prior;
        });
      } else {
        // Remove employee from the selectedEmployees mapping for the manager
        if (updatedEmployees[managerId]) {
          updatedEmployees[managerId] = updatedEmployees[managerId].filter(
            (id) => id !== employeeId
          );
        }

        // Remove manager from the selectedEmployees if no employees selected for them
        if (
          updatedEmployees[managerId] &&
          updatedEmployees[managerId].length === 0
        ) {
          delete updatedEmployees[managerId];

          // Uncheck the manager if no employees are selected
          setSelectedManagers((prior) =>
            prior.filter((id) => id !== managerId)
          );
        }
      }

      return updatedEmployees;
    });
  };

  const handleClearEmployeeSelection = () => {
    setSelectedEmployees((prevSelectedEmployees) => {
      const managerId = currentManager;
      const updatedEmployees = { ...prevSelectedEmployees };

      // Clear the selection for the current manager
      delete updatedEmployees[managerId];

      return updatedEmployees;
    });

    setSelectedManagers((prevSelectedManagers) =>
      prevSelectedManagers.filter((id) => id !== currentManager)
    );
  };

  const handleInviteBasedSurveyChange = (event) => {
    const isChecked = event.target.checked;
    setInviteBasedSurvey(isChecked);

    if (!isChecked) {
      setSelectedEmployees({});
      setManagerInvitesEmployee(false);
    } else {
      setSelectedManagers([]);
    }
  };

  const handleManagerAdd = () => {
    setIsManagerModalOpen(true);
  };

  const handleEmployeeAdd = () => {
    setIsEmployeeModalOpen(true);
  };

  const handleManagerModalClose = () => {
    setIsManagerModalOpen(false);
  };

  const handleEmployeeModalClose = () => {
    setIsEmployeeModalOpen(false);
  };

  const handleManagerModalSubmit = async (managerData) => {
    try {
      const result = await uploadUsersHandler([managerData], "manager", true);

      // Assuming the result contains the newly created manager object
      if (result?.data?.results?.length === 1) {
        const newManager = result.data.results[0];

        await getUsers();

        // Select the new manager
        setCurrentManager(newManager.id);
        notify(t("general.manager_added_successfully"), "success");
      } else {
        notify(t("general.manager_not_added"), "error");
      }

      // Close the manager modal
      setIsManagerModalOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEmployeeModalSubmit = async (newEmployeeData) => {
    try {
      const result = await uploadUsersHandler(
        [{ ...newEmployeeData, manager_user_ids: [currentManager] }],
        "employee",
        true
      );

      // Assuming the result contains the newly created employee object
      if (Object.keys(result?.data?.results)?.length === 1) {
        // get value of first key in object

        let employeeid;
        let managerid;

        for (const [key, value] of Object.entries(result?.data?.results)) {
          employeeid = Number(value);
          managerid = Number(key);
        }

        await getUsers();

        setSelectedManagers((prevSelectedManagers) => {
          if (!prevSelectedManagers.includes(managerid)) {
            return [...prevSelectedManagers, managerid];
          }
          return prevSelectedManagers;
        });

        setSelectedEmployees((prevSelectedEmployees) => {
          // return newEmployee;
          const managerId = currentManager;
          const updatedEmployees = { ...prevSelectedEmployees };

          if (updatedEmployees[managerId]) {
            // check if employee already exists in selected employees
            if (!updatedEmployees[managerId].includes(employeeid)) {
              updatedEmployees[managerId].push(employeeid);
            }
          } else {
            updatedEmployees[managerId] = [employeeid];
          }

          return updatedEmployees;
        });

        notify(t("general.employee_added_successfully"), "success");
      } else {
        notify(t("general.employee_not_added"), "error");
      }

      setIsEmployeeModalOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  function switchClassNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  const handleLaunch = useCallback(
    async (type) => {
      setIsLoading(true);

      const userDetails =
        managerInvitesEmployee || !inviteBasedSurvey
          ? selectedManagers
          : selectedEmployees;

      const surveyInputData = {
        invite_based: inviteBasedSurvey,
        survey_invite_handler: managerInvitesEmployee ? "manager" : "admin",
        company_template: selectedCompanyTemplate,
        user: userDetails,
        self_survey_requested: selfSurveyRequested,
        survey_start_date: startDate,
        survey_duration: surveyDuration,
        block_title: surveyTitle,
        type: type,
        draft_survey_id: draftSurveyDetails?.id || null,
      };

      try {
        if (type === "draft" && draftSurveyDetails) {
          await updateSurveyHandler(draftSurveyDetails.id, {
            ...surveyInputData,
            manager_employee_pairing_data: userDetails,
          });
        } else {
          await createSurveyHandler(surveyInputData);
        }

        await getSurveyOverviewResults();

        if (type === "draft") {
          notify(t("general.survey_saved_as_draft"), "success");
        } else if (type === "launch") {
          notify(t("general.survey_launched"), "success");
        }

        setCreateNewSurvey(false);
        setShowFirstPage(true);
      } catch (error) {
        console.log(error);
      }

      setIsLoading(false);
    },
    [
      createSurveyHandler,
      getSurveyOverviewResults,
      managerInvitesEmployee,
      selectedCompanyTemplate,
      selectedEmployees,
      startDate,
      surveyDuration,
      setCreateNewSurvey,
      selectedManagers,
      inviteBasedSurvey,
      surveyTitle,
      notify,
      updateSurveyHandler,
      draftSurveyDetails,
      setShowFirstPage,
      selfSurveyRequested,
      t,
    ]
  );

  const handleTemplateChange = (event) => {
    setSelectedCompanyTemplate(event.target.value);
  };

  const handleStartDateChange = (event) => {
    // ensure start date is from the future

    const selectedStartDate = event.target.value;

    const today = new Date();
    const selectedDate = new Date(selectedStartDate);

    if (selectedDate.setHours(0, 0, 0, 0) < today.setHours(0, 0, 0, 0)) {
      notify("Start date must be today onwards", "error");
      return;
    }

    setStartDate(selectedStartDate);
  };

  const handleDurationChange = (event) => {
    const enteredDuration = event.target.value;
    setSurveyDuration(enteredDuration);
  };

  const handleManagerInvitesEmployeeChange = (event) => {
    const isChecked = event.target.checked;
    setManagerInvitesEmployee(isChecked);

    if (isChecked) {
      setSelectedEmployees({});
    } else {
      setSelectedManagers([]);
    }
  };

  useEffect(() => {
    getTags();
  }, [getTags]);

  const tagByID = useMemo(() => {
    let tags_array = [];
    if (tags) {
      tags.map((item) => {
        tags_array[item.id] = item.tag;
        return false;
      });
    }
    return tags_array;
  }, [tags]);

  const selectManagersHandler = (type) => {
    if (type === "select") {
      setSelectedManagers(managers.map((manager) => manager.id));
      // Select all employees for each manager if inviteBasedSurvey is true
      if (inviteBasedSurvey) {
        setSelectedEmployees(
          managers.reduce((acc, manager) => {
            acc[manager.id] = employees
              .filter((employee) =>
                employee.manager_user_ids.includes(manager.id)
              )
              .map((employee) => employee.id);
            return acc;
          }, {})
        );
      }
    } else if (type === "clear") {
      setSelectedManagers([]);
      setSelectedEmployees({});
      setCurrentManager(null);
    }
  };

  const openLinkEmployeeModal = () => {
    setLinkEmployeeModalOpen(true);
  };

  const closeLinkEmployeeModal = () => {
    setLinkEmployeeModalOpen(false);
  };

  const handleLinkEmployees = async (employeeIds) => {
    const employeeData = employeeIds.map((id) => {
      return { id, manager_user_ids: [currentManager], linkmanager: true };
    });

    const results = await editUsers("employees", "update", [...employeeData]);

    if (results?.length > 0) {
      notify(
        `${results.length} employee(s) linked successfully to the manager.`,
        "success"
      );
    } else {
      notify("Employees could not be linked to the manager.", "error");
    }

    closeLinkEmployeeModal();

    // Refresh the employees list
    await getUsers();
  };

  useEffect(() => {
    // Get the current date
    const currentDate = new Date();

    // Format the date as 'YYYY-MM-DD' for the input element
    const formattedToday = currentDate.toISOString().split("T")[0];

    // Set the min attribute of the input element to tomorrow's date
    const startDateInput = document.getElementById("start-date-input");
    if (startDateInput) {
      startDateInput.min = formattedToday;
    }
  }, []); // Empty dependency array ensures this effect runs only once

  const handleSellfSurveyRequestedChange = (event) => {
    const isChecked = event.target.checked;
    setSelfSurveyRequested(isChecked);
  };

  return (
    <>
      <div className="flex justify-between items-center mb-4 top-bar">
        <button
          onClick={() => {
            setCreateNewSurvey(false);
            setShowFirstPage(true);
          }}
          className={`button border-red-500 !text-red-500 hover:!bg-red-600 hover:!text-white`}
        >
          {t("create_survey.cancel_btn")}
        </button>
        <div className="space-x-4">
          <button
            onClick={() => handleLaunch("draft")}
            disabled={
              surveyTitle === "" || selectedCompanyTemplate === "" || isLoading
            }
            className={`button disabled:cursor-not-allowed disabled:opacity-50`}
          >
            {draftSurveyDetails
              ? t("create_survey.save_draft")
              : t("create_survey.create_draft")}
          </button>
          <button
            onClick={() => handleLaunch("launch")}
            disabled={isLaunchDisabled || isLoading}
            className={`button ${
              isLaunchDisabled || isLoading
                ? "cursor-not-allowed opacity-50"
                : ""
            }`}
          >
            {isLoading
              ? t("create_survey.launch_btn_loading")
              : t("create_survey.launch_btn")}
          </button>
        </div>
      </div>
      <div className="box-white">
        {/* Template bar */}
        <div className="flex mb-6">
          <div className="lg:w-1/4 w-full lg:pr-3">
            <label htmlFor="template-select" className="block mb-1">
              {t("create_survey.template_label")}
            </label>
            <FormControl fullWidth>
              <Select
                id="template-select"
                onChange={handleTemplateChange}
                value={selectedCompanyTemplate}
              >
                {companyTemplates.map((template) => (
                  <MenuItem key={template.id} value={template.id}>
                    {template.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="lg:w-1/4 w-full lg:px-3">
            <label htmlFor="start-date-input" className="block mb-1">
              {t("create_survey.start_date_label")}
            </label>
            <span className="date-wrapper">
              <input
                value={startDate}
                id="start-date-input"
                type="date"
                onChange={handleStartDateChange}
                className="w-full"
              />
            </span>
          </div>
          <div className="lg:w-1/4 w-full lg:px-3">
            <label htmlFor="duration-select" className="block mb-1">
              {t("create_survey.duration_label")}
            </label>

            <FormControl fullWidth>
              <Select
                value={surveyDuration}
                id="duration-select"
                onChange={handleDurationChange}
              >
                {Array.from({ length: 15 }, (_, index) => index + 14).map(
                  (days) => (
                    <MenuItem key={days} value={days}>
                      {days} days
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </div>
          <div className="lg:w-1/4 w-full lg:pl-3">
            <label htmlFor="survey_title" className="block mb-1">
              {t("create_survey.survey_title_lable")}
            </label>

            <input
              value={surveyTitle}
              className="w-full"
              type="text"
              onChange={(e) => setSurveyTitle(e.target.value)}
            />
          </div>
        </div>
        <div className="flex mb-6">
          <div>
            {" "}
            <input
              type="checkbox"
              id="managerInvitesEmployee"
              className="hidden"
              checked={managerInvitesEmployee}
              onChange={(e) => handleManagerInvitesEmployeeChange(e)}
            />
            <label
              htmlFor="managerInvitesEmployee"
              className={`items-center !w-auto ${
                !inviteBasedSurvey &&
                "pointer-events-none opacity-50 flex cursor-pointer"
              }`}
            >
              <span
                className={switchClassNames(
                  managerInvitesEmployee ? "bg-primary" : "bg-[#F8F8FC]",
                  "align-middle relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                )}
              >
                <span
                  aria-hidden="true"
                  className={switchClassNames(
                    managerInvitesEmployee ? "translate-x-5" : "translate-x-0",
                    "inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </span>
              <span className="ml-3 title-primary-base align-middle inline-block">
                {t("create_survey.managers_invite_employees_label")}
              </span>
            </label>
          </div>
          <div>
            {" "}
            <input
              type="checkbox"
              id="inviteBasedSurvey"
              className="hidden"
              checked={inviteBasedSurvey}
              onChange={(e) => handleInviteBasedSurveyChange(e)}
            />
            <label htmlFor="inviteBasedSurvey" className="items-center !w-auto">
              <span
                className={switchClassNames(
                  inviteBasedSurvey ? "bg-primary" : "bg-[#F8F8FC]",
                  "align-middle relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                )}
              >
                <span
                  aria-hidden="true"
                  className={switchClassNames(
                    inviteBasedSurvey ? "translate-x-5" : "translate-x-0",
                    "inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </span>
              <span className="ml-3 title-primary-base align-middle inline-block">
                {t("create_survey.invite_based_survey_label")}
              </span>
            </label>
          </div>
          <div>
            {" "}
            <input
              type="checkbox"
              id="selfSurveyRequested"
              className="hidden"
              checked={selfSurveyRequested}
              onChange={(e) => handleSellfSurveyRequestedChange(e)}
            />
            <label
              htmlFor="selfSurveyRequested"
              className="items-center !w-auto"
            >
              <span
                className={switchClassNames(
                  selfSurveyRequested ? "bg-primary" : "bg-[#F8F8FC]",
                  "align-middle relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                )}
              >
                <span
                  aria-hidden="true"
                  className={switchClassNames(
                    selfSurveyRequested ? "translate-x-5" : "translate-x-0",
                    "inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </span>
              <span className="ml-3 title-primary-base align-middle inline-block">
                {t("create_survey.self_survey_requested_label")}
              </span>
            </label>
          </div>
        </div>
        <div>
          {managers && employees ? (
            <form className="flex">
              <div
                className={`w-1/2 bg-[#F3F3FA] rounded-[18px] p-7${
                  !managerInvitesEmployee && inviteBasedSurvey && currentManager
                    ? " pr-0 rounded-r-none"
                    : ""
                }`}
              >
                <button
                  type="button"
                  onClick={handleManagerAdd}
                  className={`button float-right !min-w-0 btn-sm btn-secondary !py-[6px] !px-3 ${
                    !managerInvitesEmployee &&
                    inviteBasedSurvey &&
                    currentManager
                      ? " mr-7"
                      : ""
                  }`}
                >
                  {t("create_survey.add_manager_btn")}
                </button>
                <button
                  type="button"
                  onClick={() => selectManagersHandler("select")}
                  className={`button float-right mr-5 !min-w-0 btn-sm btn-secondary !py-[6px] !px-3`}
                >
                  {inviteBasedSurvey
                    ? t("create_survey.select_all_managers_and_employees")
                    : t("create_survey.select_all_managers")}
                </button>
                {selectedManagers.length > 0 && (
                  <button
                    type="button"
                    onClick={() => selectManagersHandler("clear")}
                    className={`button float-right mr-5 !min-w-0 btn-sm btn-danger !py-[6px] !px-3`}
                  >
                    {t("create_survey.clear_selection")}
                  </button>
                )}

                <h3 htmlFor="manager" className="title-primary-md mb-8">
                  {t("create_survey.select_manager_title")}
                </h3>
                <div className="checkbox-list">
                  {managers?.length > 0 &&
                    managers.map((manager) => (
                      <div
                        key={manager.id}
                        className={`flex items-center p-2 rounded-lg ${
                          manager.id === currentManager ? " bg-[#dedff1]" : ""
                        }`}
                      >
                        <input
                          type="checkbox"
                          id={`manager-${manager.id}`}
                          value={manager.id}
                          checked={
                            selectedEmployees[manager.id]?.length > 0 ||
                            (!inviteBasedSurvey &&
                              selectedManagers.includes(manager.id))
                          }
                          onChange={handleManagerChange}
                          className="mr-2"
                        />
                        <label
                          htmlFor={`manager-${manager.id}`}
                          className="select-none cursor-pointer !mb-0 !w-full"
                        >
                          {manager.first_name} {manager.last_name}
                          {manager.manager_tag_ids.length > 0 &&
                            tags &&
                            tagByID.length > 0 && (
                              <small className="ml-2 font-bold">
                                ({tagByID[manager.manager_tag_ids[0]]})
                              </small>
                            )}
                        </label>
                      </div>
                    ))}
                </div>
              </div>

              {!managerInvitesEmployee && inviteBasedSurvey && currentManager && (
                <div className="w-[calc(50%+20px)] -ml-5 bg-[#dedff1] rounded-[18px] p-7">
                  <div className="sticky top-4">
                    <button
                      disabled={currentManager === null}
                      type="button"
                      onClick={handleEmployeeAdd}
                      className="button float-right !min-w-0 btn-sm btn-secondary !py-[6px] !px-3"
                    >
                      {t("create_survey.add_employee_btn")}
                    </button>
                    <button
                      disabled={currentManager === null}
                      type="button"
                      onClick={openLinkEmployeeModal}
                      className="button float-right mr-5 !min-w-0 btn-sm btn-secondary !py-[6px] !px-3"
                    >
                      {t("create_survey.link_existing_employee")}
                    </button>

                    {selectedEmployees[currentManager] &&
                      selectedEmployees[currentManager].length > 0 && (
                        <button
                          type="button"
                          onClick={handleClearEmployeeSelection}
                          className={`button float-right mr-5 !min-w-0 btn-sm btn-danger !py-[6px] !px-3`}
                        >
                          {t("create_survey.clear_selection")}
                        </button>
                      )}
                    <h3 className="block title-primary-md mb-8">
                      {t("create_survey.select_employee_title")}
                    </h3>

                    <div className="checkbox-list">
                      {currentManager &&
                        employees?.length > 0 &&
                        employees.map((employee) => {
                          const isEmployeeUnderSelectedManager =
                            employee.manager_user_ids.includes(currentManager);

                          if (isEmployeeUnderSelectedManager) {
                            const managerId = currentManager;
                            const selected = selectedEmployees[managerId]
                              ? selectedEmployees[managerId].includes(
                                  employee.id
                                )
                              : false;

                            return (
                              <div
                                key={employee.id}
                                className="flex items-center p-2"
                              >
                                <input
                                  type="checkbox"
                                  id={`employee-${employee.id}`}
                                  value={employee.id}
                                  checked={selected}
                                  onChange={handleEmployeeChange}
                                  className="mr-2"
                                  key={`employee-checkbox-${employee.id}`}
                                />
                                <label
                                  htmlFor={`employee-${employee.id}`}
                                  className="select-none cursor-pointer !mb-0"
                                >
                                  {employee.first_name} {employee.last_name}
                                </label>
                              </div>
                            );
                          }

                          return null;
                        })}
                    </div>
                  </div>
                </div>
              )}
            </form>
          ) : (
            <SkeletonCreateSurvey />
          )}

          {/* Manager modal */}

          <NewManagerModal
            isManagerModalOpen={isManagerModalOpen}
            handleManagerModalClose={handleManagerModalClose}
            handleManagerModalSubmit={handleManagerModalSubmit}
          />

          {/* Employee modal */}

          <NewEmployeeModal
            isEmployeeModalOpen={isEmployeeModalOpen}
            handleEmployeeModalClose={handleEmployeeModalClose}
            handleEmployeeModalSubmit={handleEmployeeModalSubmit}
          />

          {/* Link Employee Modal */}

          {employees?.length > 0 && (
            <LinkEmployeeModal
              linkEmployeeModalOpen={linkEmployeeModalOpen}
              employees={
                employees.filter(
                  (employee) =>
                    !selectedEmployees[currentManager]?.includes(employee.id)
                ) || []
              }
              handleLinkEmployees={handleLinkEmployees}
              closeLinkEmployeeModal={closeLinkEmployeeModal}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default CreateSurvey;
