import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { PolarArea } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Carousel, IconButton } from "@material-tailwind/react";

const imageUrl = process.env.REACT_APP_IMAGE_URL;

const ManagerDetails = ({ selectedManager, categoriesData }) => {
  const { t } = useTranslation();

  const [selectedCategory, setSelectedCategory] = useState("");
  const [feedbackList, setFeedbackList] = useState([]);
  const [view, setView] = useState("user");

  const piePalette = useMemo(
    () => [
      {
        name: "light-green",
        backgroundColor: "rgba(216, 240, 232, 0.7)",
        selectedCategoryBackgroundColor: "rgba(200, 220, 210, 1)",
      },
      {
        name: "light-blue",
        backgroundColor: "rgba(222, 238, 249, 0.7)",
        selectedCategoryBackgroundColor: "rgba(200, 220, 240, 1)",
      },
      {
        name: "light-brown",
        backgroundColor: "rgba(252, 244, 228, 0.9)",
        selectedCategoryBackgroundColor: "rgba(230, 220, 200, 1)",
      },
      {
        name: "dark-purple",
        backgroundColor: "rgba(229, 227, 241, 0.8)",
        selectedCategoryBackgroundColor: "rgba(210, 200, 230, 1)",
      },
      {
        name: "light-purple",
        backgroundColor: "rgba(237, 228, 246, 0.8)",
        selectedCategoryBackgroundColor: "rgba(220, 210, 230, 1)",
      },
      {
        name: "dark-red",
        backgroundColor: "rgba(251, 230, 237, 0.8)",
        selectedCategoryBackgroundColor: "rgba(230, 200, 210, 1)",
      },
      {
        name: "hot-orange",
        backgroundColor: "rgba(253, 126, 20, 0.7)",
        selectedCategoryBackgroundColor: "rgba(253, 126, 20, 1)",
      },
      {
        name: "sea-blue",
        backgroundColor: "rgba(0, 123, 255, 0.7)",
        selectedCategoryBackgroundColor: "rgba(0, 123, 255, 1)",
      },
    ],
    []
  );

  let radarData = useMemo(
    () => ({
      labels: categoriesData?.map(({ title }) => title),
      datasets: [
        {
          data: categoriesData?.map(
            ({ title }) => selectedManager.category_scores[title]?.latest_score
          ),
          backgroundColor: categoriesData?.map(({ palette, title }) =>
            selectedCategory === title
              ? piePalette.find((p) => p.name === palette)
                  .selectedCategoryBackgroundColor
              : piePalette.find((p) => p.name === palette).backgroundColor
          ),
          borderWidth: 0,
          circular: true,
          borderJoinStyle: "round",
          drawOnChartArea: false,
        },
      ],
    }),
    [categoriesData, selectedManager, piePalette, selectedCategory]
  );

  const getCategoryData = (category) => {
    return categoriesData.find((cat) => cat.title === category);
  };

  useEffect(() => {
    if (
      !selectedManager ||
      !categoriesData?.length ||
      !selectedManager?.all_surveys?.length
    ) {
      return;
    }

    setSelectedCategory(categoriesData[0].title);

    const allSurveys = selectedManager.all_surveys;

    if (!allSurveys.length) {
      return;
    }

    const dataFeedback = allSurveys.flatMap((survey) => {
      const questions = survey?.data?.inputs?.questions || [];
      return questions.flatMap((question) =>
        question.answers.map((answer) => ({
          type: answer.type,
          category: question.text,
          response: answer.response,
        }))
      );
    });

    setFeedbackList(dataFeedback);
  }, [selectedManager, categoriesData, piePalette]);

  const midpoint = Math.ceil(categoriesData.length / 2);

  const leftSideData = categoriesData.slice(
    0,
    midpoint - (categoriesData.length % 2 === 0 ? 0 : 1)
  );
  const rightSideData = categoriesData.slice(
    midpoint - (categoriesData.length % 2 === 0 ? 0 : 1)
  );

  const filteredAndCategorizedFeedback = Object.entries(
    feedbackList
      .filter((feedback) => feedback?.type === view)
      .reduce((categoryMap, { response, category }) => {
        if (!categoryMap[category]) {
          categoryMap[category] = [];
        }
        categoryMap[category].push(response);
        return categoryMap;
      }, {})
  );

  return (
    categoriesData?.length > 0 && (
      <div key={selectedManager?.manager.id}>
        <div className="lg:flex w-full">
          <div className="box-white lg:max-w-full lg:min-w-[66%]">
            <h3 className="title-primary-md mb-1">
              {selectedManager.manager.first_name}{" "}
              {selectedManager.manager.last_name}
            </h3>
            <p className="text-[#686285] w-full mb-6">
              {selectedManager.manager.email}
            </p>
            <div className="lg:flex items-center">
              <div className="lg:w-1/3 lg:pr-3 lg:mb-10 mb-4">
                {leftSideData.map(({ title, palette }) => (
                  <button
                    key={title}
                    className={`graph-btn ${
                      title !== selectedCategory ? "text-" : "text-white bg-"
                    }${palette}`}
                    onClick={() => setSelectedCategory(title)}
                  >
                    {title}
                    <span className="float-right">
                      <span>{`${selectedManager.category_scores[title]?.latest_score}`}</span>{" "}
                      {selectedManager.category_scores[title]?.self_score && (
                        <span className="font-light">
                          {`(${t("anaylze.managers.self_assessed")}: ${
                            selectedManager.category_scores[title]?.self_score
                          })`}
                        </span>
                      )}
                    </span>
                  </button>
                ))}
              </div>
              <div className="lg:w-1/3 lg:px-3 lg:mb-10 mb-4">
                <PolarArea
                  className="mx-auto p-2 !w-[200px] !h-[200px]"
                  data={radarData}
                  options={{
                    animation: { duration: 1400 },
                    plugins: {
                      ChartDataLabels,
                      datalabels: {
                        formatter: function (value, context) {
                          return "";
                        },
                        anchor: "end",
                        align: "center",
                        font: {
                          family: "Poppins, sans-serif",
                          weight: "bold",
                        },
                      },
                      legend: {
                        display: false,
                      },
                    },
                    clip: { left: 0, top: 0, right: 0, bottom: 0 },
                    scales: {
                      r: {
                        backgroundColor: "#F3F3FA",
                        drawOnChartArea: false,
                        angleLines: {
                          display: true,
                          color: "white",
                          lineWidth: 2,
                        },
                        grid: {
                          color: "white",
                        },
                        ticks: {
                          maxTicksLimit: 1,
                          display: false,
                        },
                        pointLabels: {
                          display: false,
                        },
                      },
                    },
                  }}
                />
              </div>
              <div className="lg:w-1/3 lg:pl-3 lg:mb-10 mb-4">
                {rightSideData.map(({ title, palette }) => (
                  <button
                    key={title}
                    className={`graph-btn ${
                      title !== selectedCategory ? "text-" : "text-white bg-"
                    }${palette}`}
                    onClick={() => setSelectedCategory(title)}
                  >
                    {title}
                    <span className="float-right">
                      <span>{`${selectedManager.category_scores[title]?.latest_score}`}</span>{" "}
                      {selectedManager.category_scores[title]?.self_score && (
                        <span className="font-light">
                          {`(${t("anaylze.managers.self_assessed")}: ${
                            selectedManager.category_scores[title]?.self_score
                          })`}
                        </span>
                      )}
                    </span>
                  </button>
                ))}
              </div>
            </div>
            {selectedCategory && (
              <div className="lg:flex lg:flex-wrap">
                <div className="lg:w-1/2 w-full lg:pr-6">
                  <h3 className="title-primary-base mb-5">
                    {t("analyze.managers.cat_description_title")}
                  </h3>

                  <div
                    className={`survey-box !bg-transparent !m-0 !p-0 w-full element-${
                      getCategoryData(selectedCategory)?.palette
                    }`}
                  >
                    <span className="survey-image !h-auto">
                      <img
                        src={
                          imageUrl + getCategoryData(selectedCategory)?.icon.url
                        }
                        alt={selectedCategory}
                      />
                      <h3 className="title-md text-center mt-3">
                        {selectedCategory}
                      </h3>
                    </span>
                    <div className="inline-block align-middle lg:max-w-[calc(100%-125px)] max-w-[calc(100%-85px)]">
                      <h4
                        className={`title-[14px] font-bold mb-4 text-${
                          getCategoryData(selectedCategory)?.palette
                        }`}
                      >
                        {getCategoryData(selectedCategory)?.text}
                      </h4>
                      <p className="text-[#686285] leading-[18px]">
                        {getCategoryData(selectedCategory)?.summary}
                      </p>
                    </div>
                  </div>
                </div>
                {getCategoryData(selectedCategory)?.skills?.length > 0 && (
                  <div className="lg:w-1/2 w-full lg:pl-6">
                    <h3 className="title-primary-base mb-5">
                      {t("analyze.managers.questions_title")}
                    </h3>
                    <Carousel
                      className={`rounded-xl bg-${
                        getCategoryData(selectedCategory).palette
                      }-light h-auto text-${
                        getCategoryData(selectedCategory).palette
                      }`}
                      prevArrow={({ handlePrev }) => (
                        <IconButton
                          variant="text"
                          onClick={handlePrev}
                          className={`!absolute top-2/4 -translate-y-2/4 left-2 w-4 h-4 hover:bg-transparent text-${
                            getCategoryData(selectedCategory).palette
                          }`}
                        >
                          <ChevronLeftIcon className="w-4 h-4" />
                        </IconButton>
                      )}
                      nextArrow={({ handleNext }) => (
                        <IconButton
                          variant="text"
                          onClick={handleNext}
                          className={`!absolute top-2/4 -translate-y-2/4 right-2 w-4 h-4 hover:bg-transparent text-${
                            getCategoryData(selectedCategory)?.palette
                          }`}
                        >
                          <ChevronRightIcon className="w-4 h-4" />
                        </IconButton>
                      )}
                      navigation={({ setActiveIndex, activeIndex, length }) => (
                        <div className="absolute bottom-6 left-2/4 z-50 flex -translate-x-2/4 gap-2">
                          {new Array(length).fill("").map((_, i) => (
                            <span
                              key={i}
                              className={`block h-2 w-2 cursor-pointer rounded-2xl transition-all content-[''] bg-${
                                getCategoryData(selectedCategory)?.palette
                              } ${activeIndex === i ? "" : "opacity-50"}`}
                              onClick={() => setActiveIndex(i)}
                            />
                          ))}
                        </div>
                      )}
                    >
                      {getCategoryData(selectedCategory)?.skills?.length > 0 &&
                        getCategoryData(selectedCategory)?.skills?.map(
                          (skill) =>
                            skill?.questions.length > 0 &&
                            skill?.questions.map((question) => (
                              <div className="p-8 pb-12">{question.text}</div>
                            ))
                        )}
                    </Carousel>
                  </div>
                )}

                <div className="mt-6 w-full">
                  <div className="flex justify-between items-center mb-4">
                    <h3 className="title-primary-base">
                      {t("analyze.company.feedback_title")}
                    </h3>
                    <div className="flex space-x-4 mr-4">
                      <button
                        className={`graph-btn ${
                          view === "summary"
                            ? "text-white bg-dark-purple"
                            : "text-dark-purple"
                        }`}
                        onClick={() => setView("summary")}
                      >
                        {t("analyze.managers.summary")}
                        <span className="ml-2 px-2 py-1 text-xs font-semibold text-dark-purple bg-white rounded-full">
                          Beta
                        </span>
                      </button>
                      <button
                        className={`graph-btn ${
                          view === "user"
                            ? "text-white bg-dark-purple"
                            : "text-dark-purple"
                        }`}
                        onClick={() => setView("user")}
                      >
                        {t("analyze.managers.list")}
                      </button>
                    </div>
                  </div>
                  <div className="flex mt-6 flex-wrap">
                    {filteredAndCategorizedFeedback.length > 0 ? (
                      filteredAndCategorizedFeedback.map(
                        ([category, feedbackArray]) => (
                          <div className="w-1/3" key={category}>
                            <div className="rounded-[22px] bg-[#F3F3FA] p-7 text-[#686285] mr-4 mb-4">
                              <h4 className="title-primary-base mb-3">
                                {category}
                              </h4>
                              <div className="space-y-3">
                                {feedbackArray.map((response, index) => (
                                  <div
                                    key={index}
                                    className="flex justify-between"
                                  >
                                    <div>{response}</div>
                                    {view === "user" && (
                                      <div className="text-xs ml-2">
                                        {index + 1}/{feedbackArray.length}
                                      </div>
                                    )}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        )
                      )
                    ) : (
                      <div className="w-full">
                        <div className="rounded-[22px] bg-[#F3F3FA] p-7 text-[#686285] mr-4 mb-4">
                          <h4 className="title-primary-base">
                            {t("analyze.managers.no_results")}
                          </h4>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  );
};

export default ManagerDetails;
